import {
    forwardRef,
    HTMLInputTypeAttribute,
    Ref,
    useEffect,
    useRef,
} from "react";

type Props = {
    type?: HTMLInputTypeAttribute;
    name?: string;
    id?: string;
    value?: string | ReadonlyArray<string> | number;
    className?: string;
    autoComplete?: string;
    required?: boolean;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
};

export default forwardRef<HTMLInputElement, Props>(function TextInput(
    {
        type = "text",
        name,
        id,
        value,
        className,
        autoComplete,
        required,
        handleChange,
        placeholder,
    },
    ref
) {
    return (
        <div className="flex flex-col items-start">
            <input
                type={type}
                name={name}
                id={id}
                value={value}
                className={
                    `border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 rounded-md shadow-sm ` +
                    className
                }
                ref={ref}
                autoComplete={autoComplete}
                required={required}
                onChange={(e) => handleChange(e)}
                placeholder={placeholder}
            />
        </div>
    );
});
